import type { ComponentType } from "react"
import { useState, useEffect } from "react"

export function withUrlParamOverride(Component): ComponentType {
    return (props) => {
        return <Component {...props} text={"Hi this is an override"} />
    }
}

export function withAppendUrlParams(Component): ComponentType {
    return (props) => {
        const [url, setUrl] = useState(props.href)

        useEffect(() => {
            if (window.location.search.length) {
                setUrl(url + window.location.search)
            }
        }, [])

        return <Component {...props} href={url} />
    }
}
